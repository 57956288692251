import React, { useState } from 'react'
import "../assets/css/home.css";
import "../assets/css/Modal.css";
import { useForm } from "../hooks/useForm";
// import Constantes from "./Constantes";
import axios from 'axios';
import { Modal } from './Modal';
import { useModal } from '../hooks/useModal';
import { ModalBody, ModalHeader } from 'reactstrap';
import { Navigate } from 'react-router-dom';
import PhoneInput from "react-phone-number-input/input";


const Home = () => {
  const initialForm = {
    latitud: "",
    longitud: "",
  };
  const [msgerror, setmsgerror] = useState(null)
  const [form, handleInputChange] = useForm(initialForm); // Hook para uso del custom hook useForm
  const [isOpenModal, openModal, closeModal] = useModal(false);
  const [products, setproducts] = useState([])
  const [phone, setPhone] = useState("")
  

  const validationsForm = (form) => {
    let errors = {};        
    if (!form.latitud.trim()) {
        errors.latitud = "El campo 'Latitud' es requerido";
    }
    if (!form.longitud.trim()) {
        errors.longitud = "El campo 'Longitud' es requerido";
    }
    if (!phone.trim()) {
      errors.telefono = "El campo 'telefono' es requerido";
    }
    return errors;
};

const datos = (contents) => {
  // Funcion que genera una lista con los productos y la cobertura que tienen

  let dicc_keys = Object.keys(contents.geo)

  let info = []

  for (var i = 0; i < dicc_keys.length; i = i + 1){
    contents.geo[dicc_keys[i]] = contents.geo[dicc_keys[i]].split(":")
    info.push(dicc_keys[i] + ': ' + contents.geo[dicc_keys[i]][1] + ': ' + contents.geo[dicc_keys[i]][2])
    }
  
  setproducts(info)
  openModal()
}

const ValidarCobertura = async (e) =>{
  e.preventDefault();
  const error = validationsForm(form, phone);
  const formulario = {
    latitud: form.latitud,
    longitud: form.longitud,
    phone: phone.replace('+', ""),
    user: window.sessionStorage.getItem("user"),
  };
  if (Object.keys(error).length === 0){
    await axios({
      method: "POST",
      url: `processor.php`,
      headers: {
        "Content-Type": "application/json",
      },
      data: formulario,
    })
      .then((res) => {
        if (Object.keys(res.data.geo).length === 0){
          openModal()
        }else{
          datos(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      });

    }else{
      if(error.latitud !== undefined && error.longitud !== undefined && error.telefono !== undefined){
          setmsgerror('Los campos "latitud, longitud y teléfono" no pueden estar vacíos')
      }else{
          if(error.latitud !== undefined){
              setmsgerror('El campo "latitud" no puede estar vacío')
          }else if(error.longitud !== undefined){
              setmsgerror('El campo "longitud" no puede estar vacío')
          }else{
              setmsgerror('El campo "teléfono" no puede estar vacío')
          }
      }
  }
}

  return (
    <div className='container'>
      {
        window.sessionStorage.getItem("token_user") !== null?
        (
          <div className='form-container'>
            <h1 className='title'>Verificador de Cobertura</h1>
            <div className='form'>
                <form onSubmit={ValidarCobertura} className='contact-form'>
                  <h3 className='content'> Ingrese latitud y longitud  a verificar</h3>
                    <br/>
                    <input
                        type="text"
                        name="latitud"
                        id="latitud"
                        placeholder="Latitud: ej: -33.039407"
                        value={form.latitud}
                        onChange={handleInputChange}
                        required
                    />
                    <br/>
                    <input
                        name="longitud"
                        id="longitud"
                        placeholder="Longitud: ej: -68.879536"
                        type='longitud'
                        value={form.longitud}
                        onChange={handleInputChange}
                        required
                    />
                    <br/>
                    <PhoneInput
                      name="telefono"
                      id="telefono"
                      value={phone}
                      onChange={setPhone}
                      placeholder="Teléfono cliente *"
                      required
                    />
                  <button type='submit'>Verificar</button>
                </form>
            </div>
            <div>
              <Modal isOpen={isOpenModal} closeModal={closeModal}>
                  <ModalHeader style={{display: 'block'}}>
                      <span style={{float: 'right'}}></span>
                  </ModalHeader>
                  <ModalBody>
                    <div>
                      {
                        products.length === 0 ? (
                        <>
                          <h1 className="modal-container-title">
                            ¡Usted NO tiene Cobertura!
                          </h1>
                          <h3 className='no-cobertura'>
                            Lo sentimos!
                          </h3>
                        </>
                        ):(
                          <>    
                            <h1 className="modal-container-title">
                            ¡Usted tiene Cobertura!
                            </h1>
                            <div>
                              {products.map((product, index) => (
                                <h3 key={index}>
                                  {product}
                                </h3>
                              ))}
                            </div>
                          </>
                        )
                      }
                    </div>
                  </ModalBody>
              </Modal>
            </div>
          </div>
        ):(
          <div>
              <Navigate to='/login'/>
          </div>
        )}
        <div className='message'>
            {
                msgerror !== null ?
                (
                    <div>
                        {msgerror}
                    </div>
                ):
                (
                    <span>
                    </span>
                )
            }
        </div>
    </div>
  )
}

export default Home
