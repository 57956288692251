import React from 'react'
import Login from './components/Login'
import TopBar from './components/TopBar'
import "./assets/css/App.css";
import Home from './components/Home';
import { HashRouter, Routes, Route } from 'react-router-dom';

const App = () => {
  return (
    <div>
      <TopBar/>
      <HashRouter>
          <Routes>
            <Route exact path='/' element={<Home/>}></Route>
            <Route exact path='/login' element={<Login/>}></Route>
            <Route exact path='/home' element={<Home/>}></Route>
          </Routes>
        </HashRouter>      
    </div>
  )
}

export default App