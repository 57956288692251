import React from 'react'
import "../assets/css/topbar.css";
import Logo from "../assets/img/logo.png";

const TopBar = () => {
  return (
    <div>
      {window.innerWidth <= 1000 ?(
        <div className="topbar-container">
          <div> 
            <a href="/#/" className="navbar-brand">
                <img src={Logo} height="42" alt="logoMD"/>
            </a>
          </div>
        </div>
      ) : (
        <div className="topbar-container">
          <div>
            <a href="/#/" className="navbar-brand">
                <img src={Logo} height="70" alt="logoMD"/>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default TopBar